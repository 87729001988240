import { stringify } from 'qs';

import {
  ONSITE_ENDPOINT,
  ONSITE_STATUS_ENDPOINT,
  ONSITE_CALCULATE_VISITORS_ENDPOINT,
  ONSITE_FETCH_CAMPAIGN_NAMES_ENDPOINT,
  ONSITE_VERIFY_CAMPAIGN_NAME_ENDPOINT,
  ONSITE_SEND_PROMOCODES_FILE_ENDPOINT,
  ONSITE_WIDGET_TEMPLATE_ENDPOINT,
  ONSITE_INFO_ENDPOINT,
} from '../constants/endpoints';

import axios from './axios';

const postCampaign = async (id, campaign) => {
  const { data: createdCampaign } = await axios.post(
    id ? `${ONSITE_ENDPOINT}/${id}` : ONSITE_ENDPOINT,
    campaign,
  );
  return createdCampaign;
};

const changeStatus = async (id, setObjectStatus) => {
  const { data } = await axios.post(
    `${ONSITE_STATUS_ENDPOINT}/${id}`,
    { status: setObjectStatus },
  );
  return data;
};

const fetchCampaign = async (id) => {
  const { data: campaign } = await axios.get(`${ONSITE_ENDPOINT}/${id}`);

  return campaign;
};

const fetchDownloadStat = async (id) => {
  await axios.get(`${ONSITE_INFO_ENDPOINT}/${id}`, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${id}_stat_more.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

async function deleteTemplate(id) {
  const { data: result, status } = await axios.delete(`${ONSITE_WIDGET_TEMPLATE_ENDPOINT}/${id}`);
  return { result, status };
}

const postTemplate = async (template, id) => {
  const { settings, html } = template;
  const { type: widget_type } = settings;
  const { data: createdTemplate } = await axios.post(
    id ? `${ONSITE_WIDGET_TEMPLATE_ENDPOINT}/${id}` : ONSITE_WIDGET_TEMPLATE_ENDPOINT,
    JSON.stringify({
      settings,
      widget_type,
      html,
    }),
  );

  return createdTemplate;
};

const fetchTemplateList = async (type) => {
  const { data } = await axios.get(`${ONSITE_WIDGET_TEMPLATE_ENDPOINT}/list/${type}`);

  return data;
};

const fetchCampaignList = async ({
  status = '', perPage = 10, page = 1, search = '', version = '',
}) => {
  const {
    data: { campaigns, total },
  } = await axios.get(
    `${ONSITE_ENDPOINT}?status=${status || ''}&page=${page}&per_page=${perPage}&search=${search || ''}${version ? `&version=${version}` : ''}`,
  );

  return [campaigns, total];
};

const fetchVisitors = async (fields) => {
  const {
    data: { visitors },
  } = await axios.get(
    `${ONSITE_CALCULATE_VISITORS_ENDPOINT}?${stringify(fields)}`,
  );

  return visitors;
};

const fetchCampaignNames = async (name) => {
  const { data: campaignNames } = await axios.get(
    `${ONSITE_FETCH_CAMPAIGN_NAMES_ENDPOINT}?name=${name}`,
  );

  return campaignNames;
};

const validateCampaignName = async (name, id) => {
  const { data } = await axios.get(
    `${ONSITE_VERIFY_CAMPAIGN_NAME_ENDPOINT}?name=${name}${
      id ? `&id=${id}` : ''
    }`,
  );
  return data;
};

const sendPromoCodesFile = async (file, campaignId) => {
  const data = new FormData();
  data.append('file', file);

  const {
    data: { success, count },
  } = await axios.post(`${ONSITE_SEND_PROMOCODES_FILE_ENDPOINT}/${campaignId}`, data);

  return { success, count };
};

export default {
  postCampaign,
  changeStatus,
  fetchCampaign,
  fetchDownloadStat,
  fetchCampaignList,
  fetchVisitors,
  fetchCampaignNames,
  validateCampaignName,
  sendPromoCodesFile,
  fetchTemplateList,
  postTemplate,
  deleteTemplate,
};
