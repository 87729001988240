import set from 'lodash/set';
import {
  change,
  startAsyncValidation,
  stopAsyncValidation,
  touch,
} from 'redux-form';
import {
  call, put, takeLatest, delay, cancel,
} from 'redux-saga/effects';

import { onsite as api } from '../api';
import { fileToB64 } from '../help';
import { parseWidget, convertWidget } from '../help/widgets';
import {
  mapFormValuesToOnsite,
  mapOnsiteToFormValues,
} from '../mappers/onsiteMappers';
import {
  onsite as onsiteActions,
  snackbar as snackbarActions,
} from '../redux/actions';

function* createOnsite({ payload: { values } }) {
  try {
    yield put(onsiteActions.createOnsiteRoutine.request());
    const [transformedValues, file, filename] = mapFormValuesToOnsite(values);

    if (values.version === 2) {
      // TODO fix me
      transformedValues.widgets[0].html = values.widgets[0].html;
      transformedValues.widgets[0].settings.projectData = JSON.stringify(values.widgets[0].projectData);
      transformedValues.widgets[0].settings.type = values.widgets[0].settings.type;
      transformedValues.widgets[0].settings.integrated_class = values.widgets[0].settings.integrated_class;
      transformedValues.widgets[0].settings.elements_options = values.widgets[0].settings.elements_options;
      transformedValues.widgets[0].layout = values.widgets[0].settings.type;
    }

    const { id } = transformedValues;
    const data = new FormData();
    data.append('form', JSON.stringify(transformedValues));
    if (file) {
      data.append('file', file, filename);
    }
    const onsite = yield call(api.postCampaign, id, data);

    yield put(onsiteActions.createOnsiteRoutine.success(onsite));
  } catch (error) {
    yield put(onsiteActions.createOnsiteRoutine.failure(error));
  } finally {
    yield put(onsiteActions.createOnsiteRoutine.fulfill());
  }
}

const STATUSES = {
  active: 1,
  onHold: 2,
  archived: 3,
};

const INVERT_STATUSES = {
  1: 'active',
  2: 'onHold',
  3: 'archived',
};

function* activateOnsite({ payload: { id, pageView, version } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.active);
    yield put(onsiteActions.fetchOnsiteList({
      page: 1, perPage: 10, status: pageView, version,
    }));
  } catch (error) {
    const message = error.request && error.request.status === 403 ? 'You cannot set Active status!' : 'Change status error occurred!';
    yield put(
      snackbarActions.enqueueSnackbar({
        message,
        options: { variant: 'error' },
      }),
    );
  }
}

function* holdOnsite({ payload: { id, pageView, version } }) {
  try {
    yield call(api.changeStatus, id, STATUSES.onHold);
    yield put(onsiteActions.fetchOnsiteList({ page: 1, perPage: 10, status: pageView, version }));
  } catch (error) {}
}

function* fetchOnsite({ payload }) {
  try {
    const onsite = yield call(api.fetchCampaign, payload.id);
    yield put(onsiteActions.putOnsite(mapOnsiteToFormValues(onsite, payload.isArchived)));
  } catch (error) {
    console.error(error);
  }
}

function* fetchDownloadStat({ payload }) {
  try {
    yield call(api.fetchDownloadStat, payload);
  } catch (error) {
    console.error(error);
  }
}

const mapStatus = (list) => list.map((item) => ({ ...item, status: INVERT_STATUSES[item.status] }));

function* fetchOnsiteList({
  payload: {
    page, perPage, status, search, version,
  },
}) {
  try {
    const [list, total] = yield call(api.fetchCampaignList, {
      page,
      perPage,
      status: STATUSES[status],
      search,
      version,
    });

    yield put(onsiteActions.putOnsiteList({ list: mapStatus(list), total }));
  } catch (error) {}
}

function* postTemplate({ payload: { widget, id } }) {
  try {
    yield call(api.postTemplate, convertWidget(widget, 'notClose'), id);
  } catch (error) {}
}

function* deleteTemplate({ payload: id }) {
  try {
    yield call(api.deleteTemplate, id);
  } catch (error) {}
}

function* fetchTemplateList({ payload: type }) {
  try {
    const result = yield call(api.fetchTemplateList, type);
    // TODO check arguments
    const templates = result.map((widget) => parseWidget(widget, false, 'notClose'));
    yield put(onsiteActions.putTemplateList(templates));
  } catch (error) {}
}

function* fetchVisitors({ payload: { fields, formName } }) {
  try {
    yield delay(1000);
    const result = yield call(api.fetchVisitors, fields);

    yield put(onsiteActions.putVisitors(result));
  } catch (error) {}
}

function* fetchCurrentCampaignNames({ payload: name = '' }) {
  try {
    const result = yield call(api.fetchCampaignNames, name);

    yield put(onsiteActions.putCampaignNames(result));
  } catch (error) {}
}

function* validateCampaignName({
  payload: {
    name, id, resolve, reject,
  },
}) {
  try {
    if (name) {
      yield delay(500);

      const result = yield call(api.validateCampaignName, name, id);

      if (result) {
        resolve();
      } else {
        reject({ campaign_name: 'This name is already used' });
      }
    }
  } catch (error) {
    reject();
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Campaign name validation problem!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* uploadPromoCodesFile({
  payload: {
    file, formName, field, fileFiled, campaignId,
  },
}) {
  try {
    yield put(startAsyncValidation(formName));
    const { success, count } = yield call(api.sendPromoCodesFile, file, campaignId);

    if (!success) {
      yield put(touch(formName, field));
      yield put(
        stopAsyncValidation(
          formName,
          set({}, field, 'Error with file uploading'),
        ),
      );
      yield cancel();
    }

    const base64File = yield call(fileToB64, file);

    yield put(change(formName, field, count, true));
    yield put(
      change(formName, fileFiled, { file: base64File, name: file.name }, true),
    );
    yield put(stopAsyncValidation(formName));
  } catch (error) {
    yield put(touch(formName, field));
    yield put(
      stopAsyncValidation(formName, set({}, field, 'Error with file uploading')),
    );
  }
}

export default function* watcher() {
  yield takeLatest(
    onsiteActions.createOnsiteRoutine.TRIGGER.toString(),
    createOnsite,
  );
  yield takeLatest(onsiteActions.activateOnsite.toString(), activateOnsite);
  yield takeLatest(onsiteActions.holdOnsite.toString(), holdOnsite);
  yield takeLatest(onsiteActions.fetchOnsite.toString(), fetchOnsite);
  yield takeLatest(onsiteActions.fetchDownloadStat.toString(), fetchDownloadStat);
  yield takeLatest(onsiteActions.fetchOnsiteList.toString(), fetchOnsiteList);
  yield takeLatest(onsiteActions.fetchVisitors.toString(), fetchVisitors);
  yield takeLatest(onsiteActions.postTemplate.toString(), postTemplate);
  yield takeLatest(onsiteActions.deleteTemplate.toString(), deleteTemplate);
  yield takeLatest(onsiteActions.fetchTemplateList.toString(), fetchTemplateList);
  yield takeLatest(
    onsiteActions.fetchCurrentCampaignNames.toString(),
    fetchCurrentCampaignNames,
  );
  yield takeLatest(
    onsiteActions.validateCampaignName.toString(),
    validateCampaignName,
  );
  yield takeLatest(
    onsiteActions.uploadPromoCodesFile.toString(),
    uploadPromoCodesFile,
  );
}
