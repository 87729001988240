import { cloneDeep } from 'lodash';

import { getAllWidgetItems, getWidgetEvents } from '../components/OnsiteForm/selectors';
import {
  elements,
  INITIAL_VALUES, PULLED,
  WIDGET_LAYOUT_TYPES,
} from '../constants/onsiteWidgets';
import fromHtml from '../generator/fromHtml';
import toHtml from '../generator/toHtml';

import { createWidgetElementId } from './element';
import { OnsiteFormHelper } from './formSchema';
import { getUniqueIdWithPrefix } from './getUniqueIdWithPrefix';

/**
 * @param {OnsiteWidget.Widget[]} widgets
 * @param {string} id
 * @returns {number}
 */
const getWidgetIndexById = (widgets, id) => widgets.findIndex((widget) => widget.id === id);

/**
 * @param {OnsiteWidget.Widget[]} widgets
 * @param {string} id
 * @returns {number}
 */
const getWidgetById = (widgets, id) => widgets.find((widget) => widget.id === id);

/**
 * @param {OnsiteWidget.Widget[]} widgets
 * @returns {OnsiteWidget.Widget[]}
 */
const cloneWidgets = (widgets) => [...widgets];

/**
 * @param {OnsiteWidget.Widget} widget
 * @returns {string}
 */
const getWidgetFriendlyName = (widget) => {
  const chance = widget?.settings?.chance || 0;
  const name = getWidgetName(widget);
  return `${name} (${chance}%)`;
};

/**
 * @param {OnsiteWidget.Widget} widget
 * @returns {string}
 */
const getWidgetName = (widget) => (widget?.settings?.name ? `${widget?.settings?.name}` : `Widget ${widget.id}`);

export const WidgetsHelper = {
  changeEditedWidget: (widgets, currentEditedWidget, targetWidgetId) => {
    const tagetWidget = getWidgetById(widgets, targetWidgetId);

    if (tagetWidget.id === currentEditedWidget.id) return [];

    const editedWidgetIndex = getWidgetIndexById(widgets, currentEditedWidget.id);

    const newWidgetsList = cloneWidgets(widgets);

    newWidgetsList.splice(editedWidgetIndex, 1, currentEditedWidget);

    return [tagetWidget, newWidgetsList];
  },

  addNewWidget: (widgets, timeClose) => {
    const updatedWidgetList = [
      ...widgets,
      {
        ...INITIAL_VALUES.editedWidget,
        id: getUniqueIdWithPrefix(),
        timeClose,
        settings: { chance: 0 },
      },
    ];

    return updatedWidgetList;
  },

  /**
 * @param {OnsiteWidget.Widget} widget
 * @param {object} additionalParams
 * @returns {OnsiteWidget.Widget}
 */
  cloneWidget: (widget, additionalParams = {}, createNewIds = false) => {
    const widgetCopy = cloneDeep({ ...widget, ...additionalParams });
    const allWidgetElements = getAllWidgetItems(widgetCopy);
    const oldWidgetEvents = getWidgetEvents(allWidgetElements);

    const cloneWidgetElementsOptionsSetting = (oldId, newId) => {
      if (
        !widget.settings?.elements_options
        || !widget.settings?.elements_options[oldId]
      ) return;
      if (!widgetCopy.settings.elements_options) widgetCopy.settings.elements_options = {};

      widgetCopy.settings.elements_options[newId] = cloneDeep(widget.settings?.elements_options[oldId]);
    };

    // Find event by old id and assign new event with using new element
    const cloneWidgetElementsActions = (newWidgetItems) => {
      const newEvents = getWidgetEvents(newWidgetItems);
      newWidgetItems.forEach((item) => {
        const oldEvent = oldWidgetEvents.find((ev) => ev.id === item.action);

        if (!oldEvent) return;

        const newTargetElement = newWidgetItems.find((item) => item.oldId === oldEvent.target);

        if (!newTargetElement) return;

        const newTargetEvent = newEvents.find(
          (ev) => ev.target === newTargetElement.id
            && ev.name === oldEvent.name
            && ev.label === oldEvent.label,
        );

        if (!newTargetEvent) return;

        item.action = newTargetEvent.id;
      });
    };

    if (createNewIds) {
      allWidgetElements.forEach((el) => {
        const newId = createWidgetElementId(el);

        cloneWidgetElementsOptionsSetting(el.id, newId);

        el.oldId = el.id;
        el.id = newId;
        el.friendlyId = newId;
      });

      cloneWidgetElementsActions(allWidgetElements);
    }

    return widgetCopy;
  },

  duplicateWidget: (widgets, editedWidget, targetWidgetId) => {
    let targetWidget = getWidgetById(widgets, targetWidgetId);

    if (!targetWidget) return widgets;

    if (editedWidget.id === targetWidget.id) targetWidget = editedWidget;

    const updatedWidgetList = cloneWidgets(widgets);

    const newWidget = WidgetsHelper.cloneWidget(targetWidget, { id: getUniqueIdWithPrefix(), settings: {} }, true);

    updatedWidgetList.push(newWidget);

    return updatedWidgetList;
  },

  removeWidget: (widgets, editedWidget, targetWidgetId) => {
    const targetWidgetIndex = getWidgetIndexById(widgets, targetWidgetId);
    const updatedWidgetList = cloneWidgets(widgets);
    let updatedEditedWidget = editedWidget;

    if (!targetWidgetIndex < 0) return widgets;

    updatedWidgetList.splice(targetWidgetIndex, 1);

    if (editedWidget.id === widgets[targetWidgetIndex].id) {
      updatedEditedWidget = updatedWidgetList.slice(-1)[0];
    }

    return [updatedEditedWidget, updatedWidgetList];
  },

  renameWidget: (widgets, editedWidget, targetWidgetId, newName) => {
    const targetWidgetIndex = getWidgetIndexById(widgets, targetWidgetId);
    const targetWidget = getWidgetById(widgets, targetWidgetId);
    let updatedEditedWidget = editedWidget;

    if (!targetWidget) return widgets;

    const updatedTargetWidget = WidgetsHelper.cloneWidget(targetWidget, {
      settings: {
        ...targetWidget.settings,
        name: newName,
      },
    });

    if (editedWidget.id === targetWidget.id) {
      updatedEditedWidget = WidgetsHelper.cloneWidget(updatedTargetWidget);
    }

    const updatedWidgetList = cloneWidgets(widgets);

    updatedWidgetList.splice(targetWidgetIndex, 1, updatedTargetWidget);

    return [updatedEditedWidget, updatedWidgetList];
  },

  // update current widget, save the original id
  updateWidget: (widgets, editedWidget, template) => {
    const editedWidgetIndex = getWidgetIndexById(widgets, editedWidget.id);

    if (!editedWidget) return widgets;

    const updatedWidget = WidgetsHelper.cloneWidget(editedWidget, {
      ...template,
      id: editedWidget.id,
    });

    const updatedWidgetList = cloneWidgets(widgets);

    updatedWidgetList.splice(editedWidgetIndex, 1, updatedWidget);

    return [updatedWidget, updatedWidgetList];
  },

  getAllWidgetsExceptEdited: (widgets, editedWidget) => widgets.filter((widget) => widget.id !== editedWidget.id),
};

/**
 * @param {OnsiteWidget.Widget} widget
 * @returns {}
 */
export const normalizeWidgetSettings = (widget) => {
  const initialOptions = widget.settings?.elements_options || {};
  const elements_options = {};

  const allElementsIds = getAllWidgetItems(widget).map((el) => el.id);

  Object.keys(initialOptions).forEach((optionKey) => {
    if (allElementsIds.includes(optionKey)) {
      const value = optionKey.startsWith(elements.PHONE_VERIFICATION)
        ? {
          ...initialOptions[optionKey],
          smsTemplate: initialOptions[optionKey]?.smsTemplate?.replace(/(<code>)/gi, ':otp'),
        }
        : initialOptions[optionKey];

      elements_options[optionKey] = value;
    }
  });

  return {
    ...widget.settings,
    type: widget.type,
    elements_options,
  };
};

export const parseWidgetSetting = (widget) => {
  if (Array.isArray(widget.settings)) return { chance: 100 };

  const initialOptions = widget.settings?.elements_options || {};
  const elements_options = {};

  Object.keys(initialOptions).forEach((optionKey) => {
    const value = optionKey.startsWith(elements.PHONE_VERIFICATION)
      ? {
        ...initialOptions[optionKey],
        smsTemplate: initialOptions[optionKey]?.smsTemplate?.replace(/(:otp)/gi, '<code>'),
      }
      : initialOptions[optionKey];

    elements_options[optionKey] = value;
  });

  return {
    ...widget.settings,
    elements_options,
  };
};

/**
 * @param {OnsiteWidget.Widget} widget
 * @returns {OnsiteWidget.Widget}
 */
export const normalizeWidget = (widget) => {
  const friendlyName = getWidgetFriendlyName(widget);
  const name = getWidgetName(widget);

  return {
    ...widget,
    settings: {
      ...widget.settings,
      friendlyName,
      name,
    },
  };
};

export const convertWidget = (widget, timeClose) => ({
  id: widget.id,
  settings: normalizeWidgetSettings(widget),
  layout: (widget.type === WIDGET_LAYOUT_TYPES.CAROUSEL || widget.type === PULLED) ? widget.carouselIntegratedOptions : widget.type,
  timeClose,
  html: toHtml(widget),
  slides: widget.carouselSlides,
});

export const parseWidget = (widget, isNewIds, timeClose) => {
  const settings = parseWidgetSetting(widget);
  return {
    ...INITIAL_VALUES.editedWidget,
    id: widget.id,
    settings,
    timeClose,
    type: settings.type || widget.layout || widget.widget_type,
    carouselIntegratedOptions: settings.type === WIDGET_LAYOUT_TYPES.CAROUSEL ? widget.layout || 'integrated' : INITIAL_VALUES.editedWidget.carouselIntegratedOptions,
    html: widget.html,
    ...fromHtml(widget.html, settings.type || widget.layout || widget.widget_type, isNewIds),
  };
};

/**
 * @param {OnsiteWidget.Widget[]} widgets
 * @returns {OnsiteWidget.Widget[]}
 */
export const normalizeWidgets = (widgets) => widgets.map(normalizeWidget);

export const getActualWidgtesFromForm = (formObject) => {
  const widgets = OnsiteFormHelper.getWidgets(formObject);
  const actualWidgets = [...widgets];

  const editedWidget = OnsiteFormHelper.getEditedWidget(formObject);
  const editedWidgetIndex = getWidgetIndexById(widgets, editedWidget.id);

  if (editedWidgetIndex < 0) return widgets;

  actualWidgets.splice(editedWidgetIndex, 1, editedWidget);

  return actualWidgets;
};
