import React, { PureComponent } from "react";
import IconButton from "@material-ui/core/IconButton";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import MenuList from "@material-ui/core/MenuList";

import {
  programState as programStateAction,
  user as userActions,
} from "../../redux/actions";
import { ROUTE_AUTHORIZATION, LIST } from "../../constants";
import ListItemMenu from "./ListItemMenu";
import history from "../../navigation/history";

const styles = (theme) => ({
  buttonMenu: {
    position: "absolute",
    top: 2,
    left: 5,
    display: "flex",
  },
  buttonClose: {
    display: "none",
    position: "absolute",
    top: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: 265,
    minWidth: 265,
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
    },
  },
  link: {
    display: "flex",
    paddingLeft: 36,
    paddingBottom: 50,
    color: theme.palette.grey["400"],
    "&:first-child": {
      paddingBottom: 15,
    },
  },
  comfolks: {
    margin: "35px 0 75px 36px",
    letterSpacing: 3,
    color: theme.palette.primary.dark,
  },
  menu: {
    width: 265,
  },
});

class MenuPanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape(),
    userLogoutRequest: PropTypes.func.isRequired,
    toggleBaseMenuMobile: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.getActiveMenuItemName(),
    };
  }

  onItemClick = (name) => {
    const activeItem = name.toLowerCase();
    this.setState((prevState) => ({
      activeItem: prevState.activeItem !== activeItem ? activeItem : null,
    }));
  };

  onLogout = (e) => {
    e.preventDefault();
    const { userLogoutRequest } = this.props;
    userLogoutRequest();
  };

  getActiveMenuItemName = () => {
    const { pathname } = history.location;
    return pathname.includes('onsite-next')
      ? 'onsite next'
      : pathname.split("-")[0].slice(1) || null;
  };

  toggleBaseMenu = (open) => (event) => {
    const { toggleBaseMenuMobile } = this.props;
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    toggleBaseMenuMobile(open);
  };

  render() {
    // const { allowedModules } = this.props;
    // todo поменять getAllowedModules
    const { classes } = this.props;
    const { activeItem } = this.state;

    return (
      <Paper className={classes.paper}>
        <div className={classes.menu}>
          <IconButton
            color="primary"
            className={classes.buttonClose}
            aria-label="icon-button-close"
            component="span"
            onClick={this.toggleBaseMenu(false)}
          >
            <i className="material-icons">close</i>
          </IconButton>
          <Typography variant="h6" className={classes.comfolks}>
            COMFOLKS
          </Typography>
          <MenuList component="nav">
            {LIST.map(({ name, list, icon, id }) => (
              <ListItemMenu
                key={name}
                name={name}
                list={list}
                icon={icon}
                onClick={this.onItemClick}
                isOpen={activeItem === name.toLowerCase()}
                isAllowed={true}
              />
            ))}
          </MenuList>
        </div>
        <div>
          <Link
            href="mailto:support@comfolks.com"
            color="inherit"
            className={classes.link}
          >
            Support
          </Link>
          <Link
            href={ROUTE_AUTHORIZATION}
            color="inherit"
            className={classes.link}
            onClick={this.onLogout}
          >
            Log out
          </Link>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = ({ programState = {}, user = {} }) => ({
  isOpenBaseMenuMobile: programState.isOpenBaseMenuMobile,
  // allowedModules: user.allowedModules,
  // todo поменять getAllowedModules
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleBaseMenuMobile: programStateAction.toggleBaseMenuMobile,
      userLogoutRequest: userActions.userLogoutRequest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MenuPanel));
