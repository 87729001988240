import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/isNil';

import {
  CAROUSEL_TYPES,
} from '../components/OnsiteForm/WidgetFormsFields/MainFields/FieldsByWidgetType/CarouselFields';
import { FORM_SCHEMA_ONSITE } from '../constants';
import {
  directions,
  COLUMN_QUANTITY,
  alignItems,
  fontTypeToFontFamiliesMapping,
  fontFamilyToFontTypesMapping,
  INTEGRATED,
  elements,
  isPositionedOnLeft,
  NOTIFICATION,
  NOTIFICATION_LEFT,
  ADVICE,
  ADVICE_LEFT,
  PULLED,
} from '../constants/onsiteWidgets';
import { getBorderProperty } from '../help';

import { parseBg } from './parseBg';
import parser from './parsers';

const omit = omitBy((v) => isNil(v) || v === '');

const setBackgroundFields = (element, widget) => {
  const fields = parseBg(element);
  const fieldNames = Object.keys(fields);
  fieldNames.forEach((name) => {
    widget[name] = fields[name];
  });

  return widget;
};

const setBorderFields = (element, widget) => {
  const { style = {} } = element || {};
  const {
    borderColor, borderRadius, borderWidth, boxShadow,
  } = style;

  widget.borderRadius = borderRadius;

  if (!boxShadow) {
    widget.borderWidth = borderWidth;
    widget.borderColor = borderColor;

    return widget;
  }

  const border = getBorderProperty(boxShadow);

  widget.borderWidth = border.borderWidth;
  widget.borderColor = border.borderColor;

  return widget;
};

const setOuterFields = (element, widget) => {
  const { style = {} } = element || {};
  const { padding, minHeight, maxWidth } = style;

  if (widget.type === PULLED) {
    widget.width = maxWidth;
    widget.height = minHeight;
  }

  widget.padding = padding;

  return widget;
};

const setInnerFields = (element, widget) => {
  const { style = {} } = element || {};
  const { flexDirection, justifyContent } = style;

  widget.flexDirection = flexDirection;
  widget.justifyContent = justifyContent;

  return widget;
};

const setCarouselOptions = (carousel, widget) => {
  const moveSliderOption = carousel.dataset.moveOption;
  const slideCount = carousel.dataset.slidesLength;
  const { carouselDuration } = carousel.dataset;
  const { carouselDelay } = carousel.dataset;
  const { carouselAnimationFunction } = carousel.dataset;
  const { carouselType } = carousel.dataset;
  const { slideWidth } = carousel.dataset;
  const { effect } = carousel.dataset;
  const { controlColor } = carousel.dataset;
  const { verticalMargin } = carousel.dataset;
  const { commonUrl } = carousel.dataset;


  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_SLIDE_COUNT] = slideCount;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_MOVE_OPTION] = moveSliderOption;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_DURATION] = carouselDuration;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_DELAY] = carouselDelay;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_ANIMATION_FUNCTION] = carouselAnimationFunction;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_TYPE] = carouselType;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_SLIDE_WIDTH] = slideWidth;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_EFFECT] = effect;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_CONTROL_COLOR] = controlColor;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_VERTICAL_MARGIN] = verticalMargin;
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_COMMON_URL] = commonUrl;

  return widget;
};

const setItems = (element, widget, type, horizontal, fieldName, createNewIds = false) => {
  if (!element) {
    return (widget[fieldName] = []);
  }

  const childrenLength = element.children.length;


  const items = [];

  for (let index = 0; index < childrenLength; index++) {
    const el = element.children.item(index);
    const { id } = el;

    if (!id) {
      continue;
    }

    const itemObject = parser(createNewIds, el, id, type, horizontal);

    if (Array.isArray(itemObject)) {
      items.push(itemObject[0]);
      items.push(...itemObject[1]);
    } else {
      widget.alignItems = el.style.alignItems;
      items.push(itemObject);
    }

    if (type === elements.COLLAPSE) {
      itemObject.childrenElements = setItems(el, { ...widget }, type, horizontal, fieldName, createNewIds);
    }
  }

  widget[fieldName] = items.filter((item) => !isNil(item));

  return widget;
};

const replaceEnvFontToConstructorFont = (str) => {
  const transfStr = Object.values(fontTypeToFontFamiliesMapping).reduce(
    (acc, fontName) => acc.replace(
      new RegExp(`font-family:(\\s+|)${fontName}`, 'g'),
      `font-family:${fontFamilyToFontTypesMapping[fontName]}`,
    ),
    str,
  );

  return transfStr.replace(
    new RegExp('font-family:"Times New Roman"', 'g'),
    'font-family:Times New Roman',
  );
};

const defaultFromHTML = (markup, type = 'popup', newIds) => {
  const widget = {};

  widget.type = type;
  widget.items = [];
  widget.items2 = [];

  let innerLayoutElement1 = '';
  let innerLayoutElement2 = '';

  const parser = new DOMParser();
  const transformedMarkup = replaceEnvFontToConstructorFont(markup);
  const { body } = parser.parseFromString(transformedMarkup, 'text/html');
  const outerLayoutElement = body.firstElementChild;


  widget.sizeType = outerLayoutElement.id;

  const columnList = outerLayoutElement.children;

  innerLayoutElement1 = (type === INTEGRATED) ? columnList.item(0) : columnList.item(1);
  innerLayoutElement2 = (type === INTEGRATED) ? false : columnList.item(2);


  setOuterFields(outerLayoutElement, widget);
  setBorderFields(outerLayoutElement, widget);
  setBackgroundFields(outerLayoutElement, widget);
  setInnerFields(innerLayoutElement1, widget);
  widget.columnQuantity = COLUMN_QUANTITY.ONE_COLUMN;

  const horizontal = widget.flexDirection === directions.ROW;

  setItems(innerLayoutElement1, widget, type, horizontal, 'items', newIds);

  if (innerLayoutElement2) {
    setItems(innerLayoutElement2, widget, type, horizontal, 'items2', newIds);
    widget.columnQuantity = COLUMN_QUANTITY.TWO_COLUMNS;
  }

  if (!widget.alignItems) {
    widget.alignItems = alignItems.center;
  }

  return omit(widget);
};

const notificationFromHTML = (markup, type = 'popup', newIds) => {
  const widget = {};

  widget.type = type;
  widget.items = [];
  widget.items2 = [];

  const parser = new DOMParser();
  const transformedMarkup = replaceEnvFontToConstructorFont(markup);
  const { body } = parser.parseFromString(transformedMarkup, 'text/html');
  const notificationButton = body.firstElementChild;
  const { style: buttonStyle = {} } = notificationButton.lastElementChild;
  const {
    backgroundColor: buttonColor,
    backgroundImage: buttonIcon,
    width: buttonSize,
  } = buttonStyle;

  const outerLayoutElement = body.lastElementChild;

  const isOnLeft = [...outerLayoutElement.classList]
    .map((name) => isPositionedOnLeft(name))
    .includes(true);

  if (type === ADVICE) widget.position = isOnLeft ? ADVICE_LEFT : ADVICE;
  if (type === NOTIFICATION) widget.position = isOnLeft ? NOTIFICATION_LEFT : NOTIFICATION;

  widget.sizeType = outerLayoutElement.id;

  const columnList = outerLayoutElement.children;
  const innerLayoutElement1 = columnList.item(1);
  const innerLayoutElement2 = columnList.item(2);

  setOuterFields(outerLayoutElement, widget);
  setBorderFields(outerLayoutElement, widget);
  setBackgroundFields(outerLayoutElement, widget);
  setInnerFields(innerLayoutElement1, widget);
  widget.columnQuantity = COLUMN_QUANTITY.ONE_COLUMN;

  const horizontal = widget.flexDirection === directions.ROW;

  setItems(innerLayoutElement1, widget, type, horizontal, 'items', newIds);

  if (innerLayoutElement2) {
    setItems(innerLayoutElement2, widget, type, horizontal, 'items2', newIds);
    widget.columnQuantity = COLUMN_QUANTITY.TWO_COLUMNS;
  }

  if (!widget.alignItems) {
    widget.alignItems = alignItems.center;
  }

  const result = {
    ...widget, buttonIcon, buttonColor, buttonSize,
  };

  return omit(result);
};

const setCarouselSlides = (carousel, widget, createNewIds = false, type, horizontal) => {
  let slides = [];
  if (widget.carouselType === CAROUSEL_TYPES.FULL_SCREEN_CAROUSEL) {
    slides = carousel.firstElementChild.children;
  } else {
    slides = carousel.firstElementChild.firstElementChild.children;
  }
  const slidesLength = slides.length;
  const parsedSlides = [];
  for (let i = 0; i < slidesLength; i++) {
    const slideElements = widget.carouselType === CAROUSEL_TYPES.FULL_SCREEN_CAROUSEL
      ? slides[i].firstElementChild.firstElementChild : slides[i];
    const parsedSlide = { id: i, backgroundColor: '#cccccc' };
    setItems(slideElements, parsedSlide, type, horizontal, 'items');
    setBackgroundFields(slides[i], parsedSlide);
    if (slides[i].tagName === 'A') {
      parsedSlide.link = slides[i].href;
    }
    if (widget.carouselType === CAROUSEL_TYPES.FULL_SCREEN_CAROUSEL) {
      setBackgroundFields(slides[i].lastElementChild, parsedSlide);
      if (slides[i].lastElementChild.tagName === 'A') {
        parsedSlide.link = slides[i].lastElementChild.href;
      }
    }
    parsedSlides.push(parsedSlide);
  }
  widget[FORM_SCHEMA_ONSITE.PROPERTIES.CAROUSEL_SLIDES] = parsedSlides;
};

const carouselFromHTML = (markup, type = 'carousel', newIds = false) => {
  const widget = {};

  widget.type = type;

  widget.sizeType = type;

  const parser = new DOMParser();
  const transformedMarkup = replaceEnvFontToConstructorFont(markup);
  const { body } = parser.parseFromString(transformedMarkup, 'text/html');


  const carousel = body.querySelector('[data-layout-id="carousel"]');
  if (!carousel) {
    return null;
  }
  const horizontal = widget.flexDirection === directions.ROW;


  setCarouselOptions(carousel, widget);
  setCarouselSlides(carousel, widget, type, horizontal, newIds);

  const stylesTarget = widget.carouselType === CAROUSEL_TYPES.FULL_SCREEN_CAROUSEL
    ? carousel.firstElementChild.firstElementChild
    : carousel.firstElementChild.firstElementChild.children[0];

  setBorderFields(stylesTarget, widget);
  setOuterFields(stylesTarget, widget);
  return omit(widget);
};


const fromHtml = (markup, type = 'popup', newIds = false) => {
  if (!markup) {
    return { type, items: [], items2: [] };
  }

  if (type === 'advice' || type === 'notification') {
    return notificationFromHTML(markup, type, newIds);
  }
  if (type === 'carousel') {
    return carouselFromHTML(markup, type, newIds);
  }

  return defaultFromHTML(markup, type, newIds);
};

export default fromHtml;

/*
const d = (

  <div id="notification_button" style="align-self:baseline">
    <div
      style="width:63px;height:63px;background-color:rgba(244, 6, 207, 1);box-shadow:0 1px 7px 1px rgba(0, 0, 0, 0.1);border-radius:40px;overflow:hidden;background-size:contain"></div>
  </div>
<div id="notification" className="notification"
     style="display:flex;justify-content:space-between;background-color:#ffffff;box-shadow:0 0 0 0px rgba(0, 0, 0, 0.3);box-sizing:border-box;flex-wrap:wrap;padding:20px;border-radius:0px;max-width:250px;min-height:100px;width:100%">
  <div className="cf_close-button cf_button-modal__close"></div>
  <div style="display:flex;width:100%;flex-direction:column;justify-content:flex-start"></div>
</div>
) */
