import AnalyticsIcon from '../components/svgIcons/AnalyticsIcon';
import ChatBotIcon from '../components/svgIcons/ChatBotIcon';
import CustomersIcon from '../components/svgIcons/CustomersIcon';
import DiscountIcon from '../components/svgIcons/DiscountIcon';
import EmailIcon from '../components/svgIcons/EmailIcon';
import OffersIcon from '../components/svgIcons/OffersIcon';
import OnsiteIcon from '../components/svgIcons/OnsiteIcon';
import PromoCodeIcon from '../components/svgIcons/PromoCodeIcon';
import PushIcon from '../components/svgIcons/PushIcon';
import ShareIcon from '../components/svgIcons/ShareIcon';
import SimNumbersIcon from '../components/svgIcons/SimNumbersIcon';
import SmsIcon from '../components/svgIcons/SmsIcon';
import BlacklistIcon from '../components/svgIcons/BlacklistIcon';

import {
  MODULE_CPA_ID,
  MODULE_EMAIL_ID,
  MODULE_ONSITE_ID,
  MODULE_ONSITE_NEXT_ID,
  MODULE_SMS_ID,
  MODULE_SOCIAL_SHARING_ID,
  MODULE_WEBPUSH_ID,
  MODULE_ANALYTICS_ID,
  MODULE_PROMO_CODES_ID,
  MODULE_CHAT_BOT_ID,
  MODULE_SIM_NUMBERS_ID,
  MODULE_CUSTOMERS,
  MODULE_OFFER_CAMPAIGNS_ID, MODULE_BLACK_LIST_ID,
} from './moduleIds';

const SUBLIST_PUSH = ['Create Push', 'My Web-push Campaigns'];
const SUBLIST_ONSITE = ['Create Onsite Campaign', 'My Onsite Campaigns'];
const SUBLIST_ONSITE_NEXT = ['Create Campaign', 'My Campaigns'];
const SUBLIST_EMAIL = ['Create Email', 'My Campaigns'];
const SUBLIST_OFFER_CAMPAIGNS = ['Create Offer Campaign', 'My Campaigns'];
const SUBLIST_SMS = ['Create SMS', 'My Campaigns', 'Tree create sms', 'Tree my campaigns'];
const SUBLIST_CHAT_BOT = ['Create Chat bot', 'My Campaigns'];
const SUBLIST_SHARING = ['New Campaign', 'My Campaigns'];
const SUBLIST_PROMO_CODES = ['New Upload', 'My Uploads'];
const SUBLIST_BLACK_LIST = ['New Upload', 'My Uploads'];
const SUBLIST_SIM_NUMBERS = ['New Upload', 'My Uploads', 'New Upload Points', 'My Uploads Points', 'New Upload Services', 'My Uploads Services'];
const SUBLIST_CPA_PLATFORM = [
  'New CPA offer',
  'New CPA loyalty',
  'My Campaigns',
  'My Offers',
  'MSISDN Statistics',
  'MSISDN Logs',
];
const SUBLIST_ANALYTICS = ['Metrics', 'All', 'Push', 'Email', 'Sms'];
const SUBLIST_CUSTOMERS = ['List'];

export const LIST = [
  {
    name: 'PUSH',
    list: SUBLIST_PUSH,
    icon: PushIcon,
    id: MODULE_WEBPUSH_ID,
  },
  {
    name: 'ONSITE',
    list: SUBLIST_ONSITE,
    icon: OnsiteIcon,
    id: MODULE_ONSITE_ID,
  },
  {
    name: 'ONSITE NEXT',
    list: SUBLIST_ONSITE_NEXT,
    icon: OnsiteIcon,
    id: MODULE_ONSITE_NEXT_ID,
  },
  {
    name: 'EMAIL',
    list: SUBLIST_EMAIL,
    icon: EmailIcon,
    id: MODULE_EMAIL_ID,
  },
  {
    name: 'OFFERS',
    list: SUBLIST_OFFER_CAMPAIGNS,
    icon: OffersIcon,
    id: MODULE_OFFER_CAMPAIGNS_ID,
  },
  {
    name: 'SMS',
    list: SUBLIST_SMS,
    icon: SmsIcon,
    id: MODULE_SMS_ID,
  },
  {
    name: 'CHAT BOT',
    list: SUBLIST_CHAT_BOT,
    icon: ChatBotIcon,
    id: MODULE_CHAT_BOT_ID,
  },
  {
    name: 'ANALYTICS',
    list: SUBLIST_ANALYTICS,
    icon: AnalyticsIcon,
    id: MODULE_ANALYTICS_ID,
  },
  {
    name: 'SHARING',
    list: SUBLIST_SHARING,
    icon: ShareIcon,
    id: MODULE_SOCIAL_SHARING_ID,
  },
  {
    name: 'CPA PLATFORM',
    list: SUBLIST_CPA_PLATFORM,
    icon: DiscountIcon,
    id: MODULE_CPA_ID,
  },
  {
    name: 'PROMO CODES',
    list: SUBLIST_PROMO_CODES,
    icon: PromoCodeIcon,
    id: MODULE_PROMO_CODES_ID,
  },
  {
    name: 'SIM NUMBERS',
    list: SUBLIST_SIM_NUMBERS,
    icon: SimNumbersIcon,
    id: MODULE_SIM_NUMBERS_ID,
  },
  {
    name: 'BLACK LIST',
    list: SUBLIST_BLACK_LIST,
    icon: BlacklistIcon,
    id: MODULE_BLACK_LIST_ID,
  },
  {
    name: 'CUSTOMERS',
    list: SUBLIST_CUSTOMERS,
    icon: CustomersIcon,
    id: MODULE_CUSTOMERS,
  },
];
