/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const BlacklistIcon = ({ fillColor, ...props }) => (
  <svg width={16} height={16} {...props} fill={fillColor} xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 16 16">
    <path
      d="M8 1.333c-3.682 0-6.667 2.985-6.667 6.667S4.318 14.667 8 14.667 14.667 11.682 14.667 8 11.682 1.333 8 1.333zM8 13.333c-2.944 0-5.333-2.389-5.333-5.333S5.056 2.667 8 2.667s5.333 2.389 5.333 5.333S10.944 13.333 8 13.333z"/>
    <path
      d="M10.94 5.56a.667.667 0 0 0-.94-.94L8 6.62 5.998 4.62a.667.667 0 0 0-.94.94l2.001 2.001-2.001 2.002a.667.667 0 0 0 .94.94L8 8.499l2.002 2.001a.667.667 0 1 0 .94-.94L8.94 7.56l2.001-2z"/>
  </svg>
);

BlacklistIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default BlacklistIcon;
